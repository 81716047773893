import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './headerr.css';

    const Header = () => {
        const [isOpen, setIsOpen] = useState(false);
    
        const toggleMenu = () => {
            setIsOpen(!isOpen);
        };
        const closeMenu = () => {
            setIsOpen(false);
        };
    return (
        <nav className="navbar navbar-expand-lg navbar-dark bg-light top-nav fixed-top">
            <div className="container">
                <Link className="navbar-brand" to="/">
                    <img className='loogo' src={require('../images/loogo.png')} alt="logo" />
                </Link>
             
                <button 
                    className="collapse.navbar-collapse navbar-toggler" 
                    type="button" 
                    data-toggle="collapse" 
                    data-target="#navbarResponsive" 
                    aria-controls="navbarResponsive" 
                    aria-expanded="false" 
                    aria-label="Toggle navigation"
                    onClick={toggleMenu}
                >
                    <span className="fas fa-bars"></span>
                </button>
                <div className={`collapse navbar-collapse basic-navbar-nav ${isOpen ? 'show' : ''}`} id="navbarResponsive">
                    <ul className="navbar-nav ml-auto me-auto justify-content-end w-100">
                        <li className="nav-item" onClick={closeMenu}>
                            <Link className="nav-link" to="/">Home</Link>
                        </li>
                        <li className="nav-item" onClick={closeMenu}>
                            <Link className="nav-link" to="/about">About</Link>
                        </li>
                        <li className="nav-item" onClick={closeMenu}>
                            <Link className="nav-link" to="/stories">Stories</Link>
                        </li>
                        <li className="nav-item" onClick={closeMenu}>
                            <Link className="nav-link" to="/gallery">Gallery</Link>
                        </li>
                        <li className="nav-item" onClick={closeMenu}>
                            <Link className="nav-link" to="/contact">Contact</Link>
                        </li>
                        <li className="nav-item" onClick={closeMenu}>
                            <Link className="nav-link" to="/partner">Get Involved</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}

export default Header;
