import React from 'react';
import './footer.css';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="container bottom_border">
                <div className="row">
                    <div className="col-lg-3 col-md-6 col-sm-6 col">
                        <h5 className="headin5_amrc col_white_amrc pt2">About Us</h5>
                        <p className="mb10">Child Mentorship Network is an organization that provides mentorship to children in low income communities in Nigeria. </p>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 col" >
                        <h5 className="headin5_amrc col_white_amrc pt2">Quick links</h5>
                        <ul className="footer_ul_amrc">
                            <li><Link to="/">Home</Link></li>
                            <li><Link to="/about">About</Link></li>
                            <li><Link to="/stories">Stories</Link></li>
                            <li><Link to="/gallery">Gallery</Link></li>
                            <li><Link to="/contact">Contact</Link></li>
                            <li><Link to="/contact">Donate</Link></li>
                            
                        </ul>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 col">
                        <h5 className="headin5_amrc col_white_amrc pt2">Follow us</h5>
                        <ul className="footer-social">
                            <li className='li-items'><a className="facebook hb-xs-margin" href="https://www.facebook.com/childmentorship?mibextid=ZbWKwL"><span className="hb hb-xs spin hb-facebook"><i className="fab fa-facebook-f"></i></span></a></li>
                            <li className='li-items'><a className="twitter hb-xs-margin" href="https://x.com/Child_Men_Net?t=JdV_6uzS1Q-lMV4VpbWzZA&s=09"><span className="hb hb-xs spin hb-twitter"><i className="fab fa-twitter"></i></span></a></li>
                            <li className='li-items'><a className="instagram hb-xs-margin" href="https://www.instagram.com/childmentorshipnetwork?igsh=cnQyMXh3M2w2Nmc2"><span className="hb hb-xs spin hb-instagram"><i className="fab fa-instagram"></i></span></a></li>
                            <li className='li-items'><a className="linkedin hb-xs-margin" href="https://www.linkedin.com/company/child-mentorship-network/"><span className="hb hb-xs spin hb-linkedin"><i className="fab fa-linkedin"></i></span></a></li>
                         </ul>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 ">
                        <div className="news-box">
                            <h5 className="headin5_amrc col_white_amrc pt2">Newsletter</h5>
                            <p>Latest News and Events</p>
                            <form action="#">
                                <div className="input-group">
                                    <input className="form-control" placeholder="Search for..." type="text" />
                                    <span className="input-group-btn">
                                        <button className="btn btn-secondary" type="button">Go!</button>
                                    </span>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
