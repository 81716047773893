import React from 'react';
import './gallery.css';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import { Carousel } from 'react-bootstrap'; // Import Carousel component
import ChooseSection from '../components/chooseSection';
import ChooseSection2 from '../components/chooseSection2';
import Gallery1 from '../images/gallery1.webp';
import Gallery2 from '../images/gallery2.webp';
import Gallery3 from '../images/gallery3.webp';
import Gallery4 from '../images/gallery4.webp';
import Gallery5 from '../images/gallery5.webp';
import Gallery6 from '../images/gallery6.webp';
import Gallery7 from '../images/gallery7.webp';
import Gallery8 from '../images/gallery8.webp';
import Gallery9 from '../images/gallery9.webp';

const slides = [
    { img: Gallery1 },
    { img: Gallery2 },
    { img: Gallery3 },
    { img: Gallery4 },
    { img: Gallery5 },
    { img: Gallery6 },
    { img: Gallery7 },
    { img: Gallery8 },
    { img: Gallery9 },
];

const Gallery = () => {
    return (
        <div className='gallery-page'>
            <header className='header'>
                <div className='container h-100 d-flex flex-column align-items-center justify-content-center text-light'>
                    <h1 className='text-center fw-semibold margin-top-20'>Our Gallery</h1>
                    <p className='text-center w-75 mb-5'>Our gallery is our memories and wins over the years and with you joining us we hope to create more memories that will last for a lifetime</p>
                </div>
            </header>
          <div className='carousel'>
                <Carousel interval={3000} pause={false}>
                    {slides.map((slide, index) => (
                        <Carousel.Item  key={index}>
                            <img
                                className="d-blockyyn containerStyles"
                                src={slide.img}
                                alt={`Slide ${index}`}
                                // style={{ height: '400px', objectFit: 'cover' }} // Set height and object-fit
                            />
                        </Carousel.Item>
                    ))}
                </Carousel>
                </div>
         
            <div className='bg-dark text-light py-5'>
                <ChooseSection />
            </div>
            <div className='bg-dark text-light py-5'>
                <ChooseSection2 />
            </div>
        </div>
    );
}

export default Gallery;
