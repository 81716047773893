import './App.css';
import { Routes, Route } from 'react-router-dom';
import Home from './components/home';
import Contact from './components/contact';
import Gallery from './components/gallery';
import About from './components/About';
import Stories from './components/stories';
import Donate from './components/donate'
import Footer from './components/footer';
import Header from './components/header';
import Partner from './components/partner';

function App() {
  return (
    <div >
      <Header></Header>
      <Routes>
        <Route path='/' element={<Home />}></Route>
        <Route path='/about' element={<About />}></Route>
        <Route path='/stories' element={<Stories />}></Route>
        <Route path='/gallery' element={<Gallery />}></Route>
        <Route path='/contact' element={<Contact />}></Route>
        <Route path='/donate' element={<Donate />}></Route>
        <Route path='/partner' element={<Partner />}></Route>
      </Routes>
      <Footer></Footer>

    

    </div>
  );
}

export default App;


