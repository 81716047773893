import React from 'react';
import Gallery1 from '../images/gallery1.webp';
import Gallery2 from '../images/gallery2.webp';
import Gallery3 from '../images/gallery3.webp';
import Gallery4 from '../images/gallery4.webp';
import Gallery5 from '../images/gallery5.webp';
import Gallery6 from '../images/gallery6.webp';
import Gallery8 from '../images/gallery8.webp';
import ChooseSection from '../components/chooseSection';
import ChooseSection2 from '../components/chooseSection2';
import './stories.css';

const Stories = () => {
    return (
        <div className='stories-page'>
            <header className='header'>
                <div className='container h-100 d-flex flex-column align-items-center justify-content-center text-light'>
                    <h1 className='text-center fw-semibold margin-top-20'>Our Stories</h1>
                    <p className='text-center w-75 mb-5'>We have come a long way and we always love to share our Stories.</p>
                </div>
            </header>
            <div className='container my-5'>
                        <div className="row">
                            <div className='col-lg-6 d-flex flex-column justify-content-center mb-4 mb-lg-0'>
                                <h2 className='mb-4 mb-lg-5'>How Important Is Getting Parents to Provide Necessary Support?</h2>
                                <p>The role parents/guardians play in the education and grooming of their children/wards cannot be overemphasized.
                                    <p></p>
                                    At childmentorshipnetwork we understand that for any meaningful intervention to work in the lives of children parents/guardians have a role to play and must be involved.

                                </p>
                            </div>
                            <div className='col-lg-6 d-flex justify-content-center'>
                                <img src={Gallery4} className='img-fluid w-75' alt="" />
                            </div>

                        </div>
                    </div>


                    <div className='container my-5'>
                        <div className="row">
                            <div className='col-lg-6 d-flex justify-content-center'>
                                <img src={Gallery3} className='img-fluid w-75' alt="" />
                            </div>
                            <div className='col-lg-6 d-flex flex-column justify-content-center mb-4 mb-lg-0'>
                                <p className='margin-top'>
                                    On Wednesday, one of our Team Lead @Joshua Ebuka Chukwu facilitated a session that helped the parents/guardians of some of  the pupils in our mentorship program to understand the invaluable role they play in mentoring their children.
                                    <p></p>
                                    They also gave their full consent for their children to be paired with a professional for mentorship. They were so excited about our initiative and are looking forward to the impact the network will have on their children.
                                </p>
                            </div>


                        </div>
                    </div>
                    <div className=' container my-5'>
                        <div className="row">
                            <div className=' col-lg-6 d-flex flex-column justify-content-center mb-4 mb-lg-0'>
                                <h2 className='mb-4 mb-lg-5'>We Can't Keep Calm! We Got Our First Batch Of Tablets! 30th March 2021</h2>
                                <p>
                                    The past few weeks have been quite emotional for us. Receiving donations from kind hearted individuals has helped us realize how committed people are to change the narratives for children in low income communities.

                                    The joy of Pupils, Parents, Head Teacher and the entire school community couldn't be contained.
                                    We are so optimistic about the interesting and exciting experience mentees will have with their mentors with these devices. These tablets will also serve as learning devices for these pupils.
                                    We are deeply grateful to everyone who responded, shared and supported our call for donations of tablets.
                                    The target is still 100 tablets for 100 pupils in 4 low income communities in Ogun State. 12 have been given out, we are in need of 88 more.
                                </p>

                            </div>
                            <div className='col-lg-6 d-flex justify-content-center'>
                                <img src={Gallery5} className='img-fluid w-75' alt="" />
                            </div>

                        </div>
                    </div>



                    <div className='container my-5'>
                        <div className="row">
                            <div className='col-lg-6 d-flex justify-content-center'>
                                <img src={Gallery6} className='img-fluid w-75' alt="" />
                            </div>
                            <div className='col-lg-6 d-flex flex-column justify-content-center mb-4 mb-lg-0'>
                                <p className='margin-top-10'>
                                    We are so excited at the successful launch of 12 tablets for this first batch to 12 mentees at the school of our Team Lead Joshua Ebuka Chukwu

                                    <p></p>
                                    The joy of Pupils, Parents, Head Teacher and the entire school community couldn't be contained.
                                    <p></p>
                                    We are so optimistic about the interesting and exciting experience mentees will have with their mentors with these devices. These tablets will also serve as learning devices for these pupils.
                                    We are deeply grateful to everyone who responded, shared and supported our call for donations of tablets.
                                    <p></p>
                                    The target is still 100 tablets for 100 pupils in 4 low income communities in Ogun State. 12 have been given out, we are in need of 88 more.</p>



                            </div>
                        </div>
                    </div>
                    <div className='container my-5 '>
                        <div className="row">
                            
                            <div className='col-lg-6 d-flex flex-column justify-content-center mb-4 mb-lg-0'>

                                <h2 className='margin-top-10' > SELF LEADERSHIP/AWARENESS May 19th 2021
                                </h2>
                                <p className='margin-top-10'>
                                    Ability to be self aware and lead self is a vital tool for successful living.

                                    <p></p>

                                    Today, our mentors took their mentees through a session on self leadership. It was so exciting for us as mentees learnt about making unpopular decisions in the midst of opposition. They learnt how to.politely say NO when asked to do anything that's not right and against their values.
                                    <p></p>
                                    We are committed to building a future where children understand values and are able to hold tightly to them.

                                </p>

                            </div>
                            <div className='col-lg-6 d-flex justify-content-center'>
                                <img src={Gallery8} className='img-fluid w-75' alt="" />
                            </div>
     </div>
                    </div>
                    <div className=' container my-5'>
                        <div className="row">
                            <div className=' col-lg-6 d-flex flex-column justify-content-center mb-4 mb-lg-0'>
                                <h2 className='mb-4 mb-lg-5 margin-top-10'> THANK YOU FOR A  LIFETIME INVESTMENT OCT 12,2021</h2>
                                <p>
                                    The need for literacy development can never be overemphasized and its effectiveness in helping an individual navigate through life cannot be underestimated.
                                    <p></p>
                                    It's so disturbing and alarming that over 60% of primary 6 children in public schools cannot read at their grade level.
                                    <p></p>
                                    While I was in the classroom as a  Teach for Nigeria fellow, I started my journey with over 60% of the learners not being able to identify the alphabet and over 80% couldn't read up to 2 and 3 letter words. To address this, I started a weekly reading clinic that helped over 70% to start reading up to 2 and 3 letter words with over 40% reading at their grade level
                                    <p></p>
                                    While I was leaving them, I had serious concerns. One of which was they often lacked reading texts. How will they develop on the literacy skill we made progress on without a literacy text book? - Joshua Chukwu, Founder/Team Lead, Child Mentorship Network
                                    <p></p>

                                </p>

                            </div>
                            <div className='col-lg-6 d-flex justify-content-center'>
                                <img src={Gallery1} className='img-fluid w-75' alt="" />
                            </div>

                        </div>
                    </div>
                    <div className='container my-5'>
                <div className="row">
                    <div className='col-lg-6 d-flex justify-content-center'>
                        <img src={Gallery2} className='img-fluid w-75' alt="" />
                    </div>
                    <div className='col-lg-6 d-flex flex-column justify-content-center mb-4 mb-lg-0'>

                        <p className='margin-top-10'>
                            In August, we started a campaign calling for donations and support for literacy books. You all with great hearts responded. We are glad that after a long search due to scarcity and hike in price of goods in Nigeria, we got the books.
                            <p></p>
                            Today, the kids, staff members, school authority and parents couldn't contain their joy as we distributed the books.
                            <p></p>
                            We sincerely appreciate all of you who donated and supported this, may God bless and increase you. Thank you for this lifetime investment on these kids. You won't miss your reward. Thank you to the ever supportive class.

                        </p>

                    </div>


                </div>
    
            </div>


            <div className='bg-dark text-light py-5'>
            <ChooseSection />
        </div>
        <div className='bg-dark text-light py-5'>
            <ChooseSection2 />
        </div>
        </div>
    )
}

export default Stories;
