import React from 'react';
import './contact.css';

const ContactPage = () => {
    return (
        <div className="contact-container margin-top">
            <h2>Contact Us</h2>
            <p>Email: <a href="mailto:info@childmentorship.org">info@childmentorship.org</a></p>
            <p>Phone1: +1 (775) 342-8589</p>
            <p>Phone2: +2347031313351</p>
        </div>
    );
}

export default ContactPage;
