import React from 'react';
import { Card } from 'react-bootstrap';
import './chooseSection.css';


function ChooseSection() {
  return (
    <div>
        <div className="container">
            <h2 className='text-center mb-5'>Child Mentorship Network </h2>
            <div className='row justify-content-center'>
                <div className='col-lg-5'>
                    <Card className='d-flex align-items-center border-0 h-100'>
                        <div className='mt-3'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor" class="bi bi-question-octagon-fill" viewBox="0 0 16 16">
  <path d="M11.46.146A.5.5 0 0 0 11.107 0H4.893a.5.5 0 0 0-.353.146L.146 4.54A.5.5 0 0 0 0 4.893v6.214a.5.5 0 0 0 .146.353l4.394 4.394a.5.5 0 0 0 .353.146h6.214a.5.5 0 0 0 .353-.146l4.394-4.394a.5.5 0 0 0 .146-.353V4.893a.5.5 0 0 0-.146-.353zM5.496 6.033a.237.237 0 0 1-.24-.247C5.35 4.091 6.737 3.5 8.005 3.5c1.396 0 2.672.73 2.672 2.24 0 1.08-.635 1.594-1.244 2.057-.737.559-1.01.768-1.01 1.486v.105a.25.25 0 0 1-.25.25h-.81a.25.25 0 0 1-.25-.246l-.004-.217c-.038-.927.495-1.498 1.168-1.987.59-.444.965-.736.965-1.371 0-.825-.628-1.168-1.314-1.168-.803 0-1.253.478-1.342 1.134-.018.137-.128.25-.266.25h-.825zm2.325 6.443c-.584 0-1.009-.394-1.009-.927 0-.552.425-.94 1.01-.94.609 0 1.028.388 1.028.94 0 .533-.42.927-1.029.927"/>
</svg>
                        </div>
                        <Card.Body>
                            <Card.Title className='text-center text-capitalize mb-3'>WHY WE EXIST
                                
                            </Card.Title>
                            <Card.Text className='text-center'>
                            Nigeria has millions of children that are out of school. Most of these children are  living in low income communities. They lack inspiration and motivation for education. The role models, and some of the parents around them are girls who were forced into early child marriages, had unwanted pregnancy, living below poverty line, gangsterism, marital breakup, broken homes etc.
                            <p></p>
<p className='red'>Hence, Child Mentorship Network exists to ensure that no child is limited by their socio economic background. This we do by granting children in low income communities access to professionals for mentorship.
</p>

                            </Card.Text>
                        </Card.Body>
                    </Card>
                </div>
                <div className='col-lg-5'>
                    <Card className='d-flex align-items-center border-0 h-100'>
                        <div className='mt-3'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor" class="bi bi-question-octagon-fill" viewBox="0 0 16 16">
  <path d="M11.46.146A.5.5 0 0 0 11.107 0H4.893a.5.5 0 0 0-.353.146L.146 4.54A.5.5 0 0 0 0 4.893v6.214a.5.5 0 0 0 .146.353l4.394 4.394a.5.5 0 0 0 .353.146h6.214a.5.5 0 0 0 .353-.146l4.394-4.394a.5.5 0 0 0 .146-.353V4.893a.5.5 0 0 0-.146-.353zM5.496 6.033a.237.237 0 0 1-.24-.247C5.35 4.091 6.737 3.5 8.005 3.5c1.396 0 2.672.73 2.672 2.24 0 1.08-.635 1.594-1.244 2.057-.737.559-1.01.768-1.01 1.486v.105a.25.25 0 0 1-.25.25h-.81a.25.25 0 0 1-.25-.246l-.004-.217c-.038-.927.495-1.498 1.168-1.987.59-.444.965-.736.965-1.371 0-.825-.628-1.168-1.314-1.168-.803 0-1.253.478-1.342 1.134-.018.137-.128.25-.266.25h-.825zm2.325 6.443c-.584 0-1.009-.394-1.009-.927 0-.552.425-.94 1.01-.94.609 0 1.028.388 1.028.94 0 .533-.42.927-1.029.927"/>
</svg>
                        </div>
                        <Card.Body>
                            <Card.Title className='text-center text-capitalize mb-3'>
                            WHAT WE DO
                            </Card.Title>
                            <Card.Text className='text-center'>
                            We partner with schools, teachers and parents, recruit volunteers (professionals) to serve as mentors, set up mentorship clubs in schools and connect children/young people with professionals for mentorship.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </div>
               
            </div>
        </div>
    </div>
  )
}

export default ChooseSection;