import React from 'react';
import './About.css';
// import { Link } from 'react-router-dom';
import '../components/gallery';
import ChooseSection from '../components/chooseSection';
import ChooseSection2 from '../components/chooseSection2';


function About() {
  return (
    <div className='about-page'>
        <header className='header'>
            <div className='container h-100 d-flex flex-column align-items-center justify-content-center text-light'>
                <h1 className=' text-center fw-semibold margin-top-20' >About Us</h1>
                <p className='text-center w-75 mb-5'>Child Mentorship Network is an organization that provides mentorship to children/young people in low undeserved communities.</p>
            </div>
        </header>

        <div className='container my-5'>
            <div className="row">
                <div className=' container col-lg-6 d-flex flex-column justify-content-center mb-4 mb-lg-0'>
                    <p className='text-center'>We recruit professionals, train them and connect them with children and young people between the ages of 6 to 20 years for mentorship. Mentors are charged with the sole responsibility of mentorship, through motivation, inspiration and support for the child. This they do through video conferencing calls.
<p></p>CMN was founded in 2020, we recognize that to help children growing in low income areas see beyond their socioeconomic background, there is a need to connect them with professionals for mentorship.
<p></p>
We support children and schools with learning resources, safe learning space, facilitate WASH projects in schools, support parents and guardians to understand the importance of child mentorship and their role to inspire and motivate their children or wards. Teachers also receive a wide range of support from us because they are crucial in the lives of the children and play a major role in the overall development of their learners.
</p>
                </div>
            </div>
        </div>

        <div className='bg-dark text-light py-5'>
            <ChooseSection />
        </div>
        <div className='bg-dark text-light py-5'>
            <ChooseSection2 />
        </div>

        
    </div>
  )
}

export default About;
