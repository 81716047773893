import React from 'react';
import './FaqAccordion.css';
import Accordion from 'react-bootstrap/Accordion';

const FaqAccordion = () => {
    return (
        <div className='faq-section'>
        <div className='container d-flex flex-column align-items-center'>
            <h2 className='text-center text-capitalize mb-5'>Frequently asked questions</h2>
            <Accordion defaultActiveKey="" flush>
                <Accordion.Item eventKey='0'>
                    <Accordion.Header>How do I become a mentor for Child Mentorship Network?</Accordion.Header>
                    <Accordion.Body>
                    To become a mentor for Child Mentorship Network, you can visit our website and fill out the mentor application form. Our team will then review your application and contact you for further steps.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='1'>
                    <Accordion.Header>What qualifications do I need to be a mentor?</Accordion.Header>
                    <Accordion.Body>
                    We welcome mentors from various professional backgrounds. While specific qualifications may vary depending on the mentoring program, generally, mentors should have experience in their field, a passion for helping children, good communication skills, and the ability to commit to regular mentoring sessions.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='2'>
                    <Accordion.Header>Is there any training provided for mentors?</Accordion.Header>
                    <Accordion.Body>
                    Yes, we provide training for mentors to equip them with the necessary skills and knowledge to effectively mentor children. The training covers topics such as communication techniques, building rapport with mentees, understanding child development, and handling challenging situations.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='3'>
                    <Accordion.Header>Can I make a donation without becoming a mentor?</Accordion.Header>
                    <Accordion.Body>
                    Yes, absolutely! We welcome donations from individuals who may not have the capacity to become mentors but still wish to support our cause. Your donations play a crucial role in helping us provide resources, support, and mentorship opportunities to children in low-income communities
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    </div>
  )
}


export default FaqAccordion;

