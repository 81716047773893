import axios from "axios";
import Blog3Img from '../images/blog3-img.webp';
import Blog4Img from '../images/blog4-img.webp';
import Blog8Img from '../images/blog8-img.webp';

export async function getBlogFeeds(){
    try {
    var data=await axios
      .get(
        "https://script.google.com/macros/s/AKfycbyzvndjeHmHqb5hMZrq_JR8r059VMxiirOwxUEidU6Ubs2wP3UMvy4H6snuODSSTq6cRQ/exec?path=Mentorship2024"
      );
      return data.data
    } catch (error) {
      console.log(error)
    }
}

export function getImage(index){
    const data=[
        Blog3Img,
        Blog4Img,
        Blog8Img
    ]
    return data[index]
}
