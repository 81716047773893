import React from 'react';
import { Link } from 'react-router-dom';
import './partner.css';
import Bam2 from '../images/bam2.webp';
import Volunteer from '../images/volunteer.webp';
import Mc from '../images/mc.webp';
import Donate from '../images/donate.webp';
import Nam from '../images/nam.webp';

const Partner = () => {
    return (
        <div className='gallery-page'>
            <header className='header'>
                <div className='container h-100 d-flex flex-column align-items-center justify-content-center text-light'>
                    <h1 className='text-center fw-semibold margin-top-20'>Get involved</h1>
                    <p className='text-center w-75 mb-5'>Get involved and make a difference in the lives of children/young people. Whether you want to become a mentor, volunteer with us, or request mentorship, there are various ways to contribute to our cause and help shape a brighter future.</p>
                </div>
            </header>
            <div className='container'>
                <div className='row justify-content-center margin-top'>
                    <div className='col-lg-3'>
                        <div className="card h-100">
                            <img src={Bam2} className="card-img-top" alt='' />
                            <div className="card-body">
                                <h5 className="card-title">Become A Mentor</h5>
                                <p className="card-text">Professionals from different life endeavors play a major role in our work. They work to provide mentorship for the children we work with. Mentors over time have the opportunity to impact the life of a child who is thousands of miles away. Do you want to be a mentor or renew your role as a Child Mentor?</p>
                            </div>
                            <div className="card-footer" style={{ backgroundColor: 'white', border: 'none' }}>
                                <Link to="/donate" className="btn btn-primary">Apply Here</Link>
                            </div>
                        </div>
                    </div>

                    <div className='col-lg-3'>
                        <div className="card h-100">
                            <img src={Mc} className="card-img-top" alt='' />
                            <div className="card-body">
                                <h5 className="card-title">Need A Mentorship Club?</h5>
                                <p className="card-text">We set up mentorship clubs in schools. Do you need us in your school?</p>
                            </div>
                            <div className="card-footer" style={{ backgroundColor: 'white', border: 'none' }}>
                                <Link to="/donate" className="btn btn-primary ">Apply Here</Link>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-3'>
                        <div className="card h-100">
                            <img src={Nam} className="card-img-top" alt=''></img>
                            <div className="card-body">
                                <h5 className="card-title">Request for a mentor?
                                </h5>
                                <p className="card-text">Are you a teenager or young adult? Do you need a mentor to navigate through life or help you make important life decisions?</p>
                            </div>
                            <div className="card-footer" style={{ backgroundColor: 'white', border: 'none' }}>
                                <Link to="/donate" className="btn btn-primary">Apply Here</Link>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-3'>
                        <div className="card h-100">
                            <img src={Volunteer} className="card-img-top" alt='' />
                            <div className="card-body">
                                <h5 className="card-title">Volunteer with us</h5>
                                <p className="card-text">Volunteers impact our processes greatly by sacrificing hours weekly to support our work and ensure that children have access to mentorship. To volunteer with us,.</p>
                            </div>
                            <div className="card-footer" style={{ backgroundColor: 'white', border: 'none' }}>
                                <Link to="/donate" className="btn btn-primary">Apply Here</Link>
                            </div>
                        </div>
                    </div>

                </div>

                <div className='container my-5' style={{ backgroundColor: '#44485a', color: '#ffffff', padding: '30px', borderRadius: '10px', marginTop: '50px' }}>
                    <div className="row">
                        <div className='col-lg-6 d-flex flex-column justify-content-center  align-middle-on-smaller-screen mb-4 mb-lg-0'>
                            <h2 className='mb-4 mb-lg-5' >Donate/Partner With us</h2>
                            <p>We believe in collaboration! With partnership and collaboration we can do more and reach more children.
                            </p>
                            <Link to="/donate" className="btn btn-primary d-flex align-items-center justify-content-center" style={{ width: '200px', height: '60px', marginTop: '20px' }}>Donate/Partner</Link>
                        </div>
                        <div className='col-lg-6 d-flex justify-content-center'>
                            <img src={Donate} className='img-fluid w-75' alt="" />
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default Partner;
