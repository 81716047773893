
import './donate.css';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import { useState } from 'react';

function Donate() {
    const [service, setService] = useState(''); 

    const handleSubmit = (event) => {
        event.preventDefault(); 
        alert("Your form has been successfully submitted!"); 
    };

    const handleServiceChange = (event) => {
        setService(event.target.value); 
    };
  return (
    <div className='contact-page'>
        <header className='height-75 margin-top'>
            <div className='container h-100 d-flex flex-column align-items-center justify-content-center text-light'>
                <h1 className='text-center fw-semibold'>Get involved</h1>
                <p className='text-center w-75 mb-5'>Get involved and make a difference in the lives of children/young people. Whether you want to become a mentor, volunteer with us, or request mentorship, there are various ways to contribute to our cause and help shape a brighter future.</p>
            </div>
        </header>

        <div className='container my-5 d-flex justify-content-center'>
            <Form id='contact-form' onSubmit={handleSubmit}>
                <Row className='mb-3'>
                    <Col sm={12} md={6} className='mb-3 mb-md-0'>
                        <Form.Label>First Name</Form.Label>
                        <Form.Control placeholder='First name' />
                    </Col>
                    <Col sm={12} md={6}>
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control placeholder='Last name' />
                    </Col>
                </Row>

                <Form.Group className='mb-3'>
                    <Form.Label>Email</Form.Label>
                    <Form.Control type='email' placeholder='Enter email' />
                    <Form.Text className='text-muted'>
                        We'll never share your email with anyone else.
                    </Form.Text>
                </Form.Group>

                <Form.Group className='mb-3'>
                    <Form.Label>Phone Number </Form.Label>
                    <Form.Control type='tel' placeholder='Your Phone Number' />
                </Form.Group>
                <Form.Group className='mb-3'>
                    <Form.Label>Organization</Form.Label>
                    <Form.Control placeholder='Your Organization' />
                </Form.Group>
                <Form.Group className='mb-3'>
                    <Form.Label>Job Title</Form.Label>
                    <Form.Control placeholder='Job Title' />
                </Form.Group>
                <Form.Group className='mb-3'>
                        <Form.Label>What would you like to do?</Form.Label>
                        <Form.Select value={service} onChange={handleServiceChange}>
                            <option value=''>Select</option>
                            <option value='mentor'>Become a Mentor</option>
                            <option value='club'>Need A Mentorship Club</option>
                            <option value='mentorship'>Request for a Mentor</option>
                            <option value='volunteer'>Volunteer with Us</option>
                            <option value='donate'>Donate</option>
                            <option value='partner'>Partner</option>
                         
                          
                        </Form.Select>
                    </Form.Group>
                

                <Form.Group className='mb-3'>
                    <Form.Label>Your Message</Form.Label>
                    <Form.Control as="textarea" rows={3} />
                </Form.Group>

                <Button variant="danger btn-lg" type='submit'>Submit</Button>
            </Form>
        </div>
    </div>
  )
}

export default Donate;
